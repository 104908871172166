@import 'custom_bootstrap_vars';
@import '~bootstrap/scss/bootstrap';

// project specific CSS goes here

///////////////
// Variables //
///////////////

// Alert colors

$white: #fff;
$mint-green: #d6e9c6;
$black: #000;
$pink: #f2dede;
$dark-pink: #eed3d7;
$red: #b94a48;

////////////
// Alerts //
////////////

// bootstrap alert CSS, translated to the django-standard levels of
// debug, info, success, warning, error

.alert-debug {
  background-color: $white;
  border-color: $mint-green;
  color: $black;
}

.alert-error {
  background-color: $pink;
  border-color: $dark-pink;
  color: $red;
}

.logo-container {
  width: 300px;
}

.img-circle {
  border-radius: 50%;
}

tr.create td {
  color: $green;

  a {
    color: $green;
  }
}

tr.delete td {
  color: $red;

  a {
    color: $red;
  }
}

tr.update td {
  color: $blue;

  a {
    color: $blue;
  }
}

legend.form-label {
  font-size: 1em;
}

.accordion-button:focus {
  -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.accordion-button:hover {
  background-color: $gray-200 !important;
}
